<template>
  <div>
    <a
      @click="$router.back()"
      href="#"
      class="
        text-dark-75
        font-weight-bolder
        text-hover-primary
        mb-1
        font-size-lg
        d-flex
        flex-row
        align-items-center
      "
    >
      <i class="mdi mdi-chevron-left"></i>
      Kembali
    </a>
    <validation-observer ref="formSiswa">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Buat Akun Siswa
              </span>
            </h3>
            <div class="card-toolbar">
              <button
                type="submit"
                class="btn btn-success font-weight-bolder font-size-sm mr-2"
              >
                Simpan
              </button>
              <span
                @click="$router.back()"
                class="btn btn-secondary font-weight-bolder font-size-sm"
              >
                Batal</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-form-group
              id="input-group-1"
              label="nomor Induk Siswa:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Vendor"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.no_induk"
                  type="text"
                  placeholder="Masukkan Nomor Induk"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group id="input-group-1" label="Email:" label-for="input-1">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.email"
                  type="text"
                  placeholder="Masukkan Email"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Nama Siswa:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Vendor"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.name"
                  type="text"
                  placeholder="Masukkan Nama Siswa"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Alamat:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Alamat"
                rules="required"
              >
                <b-form-textarea
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.address"
                  type="text"
                  rows="3"
                  placeholder="Masukkan Alamat"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="separator separator-solid mt-2"></div>
            <br/>
            <b-form-group
              v-if="!studentId"
              id="input-group-1"
              label="Username:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.username"
                  type="text"
                  placeholder="Masukkan Username"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="!studentId"
              id="input-group-1"
              label="Password:"
              label-for="input-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.password"
                  type="password"
                  placeholder="Masukkan Password"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import {
  ROLE_STUDENT,
  UPDATE_STUDENT,
} from "@/core/services/store/user.module";
import { REGISTER } from "@/core/services/store/auth.module";

import Swal from "sweetalert2";

import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "SiswaForm",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      currentUserForm: {
        body: {
          role: ROLE_STUDENT,
        },
      },
      isLoading: false,
      editorOption: {
        // Some Quill options...
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.formSiswa.validate().then((success) => {
        this.isLoading = true;
        if (success) {
          if (this.studentId) {
            this.currentUserForm._id = this.currentUserSelected._id;
          }

          const _data = JSON.parse(JSON.stringify(this.currentUserForm));
          this.$store
            .dispatch(this.studentId ? UPDATE_STUDENT : REGISTER, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${
                  this.studentId ? "mengubah" : "membuat"
                } siswa`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/siswa`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal ${this.studentId ? "mengubah" : "membuat"} Siswa`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() {},
  },
  mounted() {
    if (this.studentId) {
      const currentUser = this.currentUserSelected;

      if (currentUser.student.full_name === undefined) {
        this.$router.back();
      }

      this.currentUserForm = {
        body: {
          role: ROLE_STUDENT,
          name: currentUser.student.full_name,
          address: currentUser.student.address,
          no_induk: currentUser.student.no_induk,
          email : currentUser.email
        },
      };
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUserSelected"]),
    studentId() {
      return this.$route.params.studentId;
    },
  },
};
</script>
